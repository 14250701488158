.content-body{
  display: flex;
  flex-direction: column;
  padding: 24px 48px;
}

.icone-pesquisa {
    display: none;
    padding-top: 7px;
  }
  
  .box-search {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 12px;
    margin: 12px 0;
  }
  
  .input-search {
    width: 60%;
  }
  
  .btn-search {
    margin-bottom: 23px;
  }
  
  .box-cad {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 80%;
    align-items: center;
    justify-content: center;
  }
  
  .box-cad-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #dee2e6;
    padding: 12px;
    gap: 8px;
    border-radius: 5px;
  }
  
  .box-cad-item-pre {
    display: flex;
    flex-direction: column;
    border: 1px solid #dee2e6;
    padding: 12px;
    gap: 8px;
    border-radius: 5px;
  }
  
  @media screen and (max-width: 478px) {
  
    .box-search {
      width: 63% !important;
    }
  
    .input-search {
      width: 100%;
    }
  }

  @media screen and (max-width: 699px){
    
    .content-body{
      padding: 5px !important;
    }

    .table td, .table th {
      padding: 5px !important;
      font-size: x-small;
    }
  }
  
  @media (max-width: 800px) {

    .card {
      width: max-content;
    }
    .box-search {
      width: calc(100% - 220px);
    }
    .txt-pesquisa {
      display: none;
    }
    .icone-pesquisa {
      display: block;
      padding-top: 7px;
    }
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 10px;
    margin-bottom: 0px !important;
  }
  
  th,
  td {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
  }
  
  th {
    background-color: #2872ba !important;
    color: white !important;
  }
  
  tr {
    background-color: white;
  }
  