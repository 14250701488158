.ficha-container {
  width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  border-collapse: collapse;
}

.ficha-table,
.experiencia-table,
.total-geral-table,
.resultado-final-table,
.assinaturas-table {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #000;
}

.ficha-table td,
.experiencia-table th,
.experiencia-table td,
.total-geral-table td,
.resultado-final-table td,
.assinaturas-table td {
  border: 1px solid #000;
  padding: 5px;
  text-align: center;
}

.ficha-table td:first-child {
  text-align: left;
}

.experiencia-table th {
  background-color: #f2f2f2;
  padding: 10px;
}

h1,
h2 {
  text-align: center;
}

p {
  margin-left: 10px;
}

.signature-section {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.signature-field {
  text-align: center;
  width: 30%;
}

.signature-line {
  border-top: 1px solid black;
  margin-bottom: 5px;
  width: 100%;
}

.logo-prefeitura {
  position: absolute;
  left: 200px;
  top: 5px;
}

.logo-spa {
  position: absolute;
  right: 200px;
  top: 5px;
}

@media print {
  .hide-on-print {
    display: none;
  }
  @page {
    size: landscape;
    background-color: white !important;
    margin: 20px !important;
  }
}

