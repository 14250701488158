@media print {
  @page {
    size: portrait;
  }
}

.class-print {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}
.logo-impressao {
  width: 100px !important;
}
.txt-center {
  font-weight: bold;
  font-size: 14px;
  vertical-align: middle !important;
}
.logo-sao-pedro {
  width: 220px !important;
}

.title {
  font-weight: 500;
  font-size: 18px;
}

.rodape {
  position: absolute;
  bottom: 0;
}
