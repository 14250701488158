/* Global styles for the entire form */
#printContent {
    background-color: white;
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  /* Ensure the logo is styled properly */
  #logo {
    max-width: 100px;
    margin-bottom: 20px;
  }
  
  /* Hide the print button when printing */
  @media print {
    .no-print {
      display: none;
    }
  }
  