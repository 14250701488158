/* QuizModal.module.css */

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  border-radius: 10px;
  padding: 50px 100px;
  text-align: center;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #550450;
  border: 1px solid #550450;
  border-radius: 100%;
  padding: 3px 10px;
  cursor: pointer;
  transition: all 0.2s;
}

.closeButton:hover {
  background-color: #550450;
  color: #fff;
}

/* Card Styles */
.cardContainer {
  display: inline-block;
  perspective: 1000px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 300px;
  background-color: #550450;
  color: #fff;
  border-radius: 15px;
  position: relative;
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d;
  cursor: pointer;
  font-family: "ADLaM Display", sans-serif;
  font-size: 24px;
}

.cardFlipped {
  transform: rotateY(180deg);
}

.cardSide {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.cardFront {
  background-color: #550450;
}

.cardBack {
  background-color: #8e2676;
  transform: rotateY(180deg);
}

/* Navigation */
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.arrowButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.arrowButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Completion Screen */
.completionScreen {
  background-color: #f3f5f9;
  padding: 30px;
  border-radius: 15px;
  width: 300px;
  margin: auto;
}

.retryButton {
  background-color: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.retryButton:hover {
  background-color: darkgreen;
}

.confettiContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 50vw;
  height: 50vh;
  pointer-events: none;
  z-index: 999;
}

@media (max-width: 768px) {
  .modalContent {
    padding: 10%;
    width: 320px;
  }

  .card {
    width: 250px;
    height: 200px;
    font-size: 16px;
  }

  .cardSide {
    width: 250px;
  }
}
