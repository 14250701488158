/* Center the table */
table {
    /* margin: 0 auto; */
    width: 100%; /* Adjust as needed */
}

/* Center table rows */
tr {
    text-align: center;
}

/* Center table header cells */
th {
    text-align: center;
}

td {
    text-align: center;
}

/* Center table body content */
tbody {
    text-align: center;
}
