@page {
    size: landscape !important;
}

.logo-container {
    display: inline-block;
    width: 30px;
    vertical-align: top;
}

.text-container {
    display: inline-block;
    width: 96%;
    vertical-align: top;
    padding-top: 25px; 
    text-align: center;
}

@media print {
    body {
        margin: 0;
        padding: 0;
    }

    .footer-class {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        page-break-before: always; 
    }

    .footer-class p {
        margin: 0;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 60px; 
    }

    th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
    }

    tr {
        page-break-inside: avoid;
    }

    .class-print {
        padding-bottom: 80px; 
    }

    .td-class {
        font-size: 10px;
        font-family: Arial, sans-serif;
    }
}


.class-print{    
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;    
}

.tabela {
    border-collapse: collapse;
    width: 100%;
}
.td-class {
    text-align: center;
    width: 20px;
    font-size: 11px;
}
.vertical {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}
.font{
    padding: 5px 0;
    font-size: 10px;
    margin: 0 !important;
}
.font-aula{
    font-size: 11px;
}

.dados-unidade{
    font-size: 14px;
}