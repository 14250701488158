.nav {
  display: flex;
  height: 125px;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f5f9;
  z-index: 1000;
  padding: 0 50px;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.1);
}

.buttonContainer {
  display: flex;
  gap: 20px;
}

.button {
  font-size: 20px;
  border-radius: 40px;
  padding: 11px 40px;
  transition: all 0.3s;
  width: fit-content;
  font-family: "Abel", sans-serif;
}

.buttonNav {
  background-color: transparent;
  color: #550450;
  border: 1px solid #550450;
}

.buttonNav:hover {
  background-color: #550450;
  color: #fff;
}

.buttonPurple {
  background-color: #550450;
  color: #fff;
  border: 1px solid #550450;
  cursor: default !important;
}

.logo {
  font-size: 36px;
  cursor: pointer;
}

.logo a {
  color: #550450;
  text-decoration: none;
  font-family: "ADLaM Display", sans-serif;
}

.main {
  position: relative;
  background-color: #550450;
  height: 100lvh;
}

.hint {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px;
}

.titleMessage {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 50%;
}

.image {
  width: 30%;
}

.titleMessage h2 {
  font-family: "ADLaM Display", sans-serif;
  font-size: 3rem;
  font-weight: 900;
  color: #fff;
}

.inputSection {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.inputContainer {
  position: relative;
  border: 2px solid #fff;
  background-color: transparent;
  border-radius: 40px;
  height: 55px;
  width: 60%;
  display: flex;
  align-items: center;
}

.inputField {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: "ADLaM Display", sans-serif;
  padding-left: 20px;
  height: 100%;
  border-radius: 40px;
}

.inputField::placeholder {
  color: #cccccc;
}

.inputField:focus {
  outline: none;
}

.sendButton {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.sendButton svg {
  fill: #550450;
}

.chatBox {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 60vh;
  overflow-y: auto;
  padding: 20px 23%;
  gap: 10px;
  width: 100vw;
  bottom: 80px;
}

.chatBox::-webkit-scrollbar {
  width: 8px;
}

.chatBox::-webkit-scrollbar-track {
  background: #f3f5f9;
  border-radius: 10px;
}

.chatBox::-webkit-scrollbar-thumb {
  background-color: #550450;
  border-radius: 10px;
  border: 2px solid #f3f5f9;
}

.chatBox::-webkit-scrollbar-thumb:hover {
  background-color: #8e2676;
}

.chatBox::-webkit-scrollbar-corner {
  background-color: #f3f5f9;
}

.message {
  max-width: 60%;
  padding: 10px 15px;
  border-radius: 40px;
  font-family: "ADLaM Display", sans-serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.userMessage {
  background-color: #8e2676;
  color: #fff;
  align-self: flex-end;
  text-align: right;
  border-top-right-radius: 0;
}

.iaMessage {
  position: relative;
  background-color: #f3f5f9;
  color: #000;
  align-self: flex-start;
  border-top-left-radius: 0;
}

.iaAvatar {
  position: absolute;
  left: -95px;
  top: 0;
  border: 3px solid #fff;
  border-radius: 100%;
}

.audioControls {
  padding-top: 10px;
}

.playPauseButton {
  background-color: transparent;
  border: 1px solid #550450;
  border-radius: 40px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #550450;
  text-transform: uppercase;
  transition: all 0.2s;
}

.playPauseButton:hover {
  background-color: #550450;
  color: #fff;
}

.loadingSpinner {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-size: 1.5rem;
}

.loadingSpinner span {
  font-family: "ADLaM Display", sans-serif;
}

.spinner {
  border: 4px solid #8e2676;
  border-left-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingCards {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 3rem;
  font-weight: bold;
  z-index: 1000;
  pointer-events: none;
}

.loadingSpinnerQuiz {
  margin-right: 10px;
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left-color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 0.8s linear infinite;
}

@media (max-width: 768px) {
  .nav {
    height: 75px;
  }

  .main {
    height: 100dvh;
  }

  .button {
    display: none;
  }

  .hint {
    flex-direction: column-reverse;
  }

  .titleMessage {
    max-width: 100%;
  }

  .titleMessage h2 {
    font-size: 2rem;
  }

  .chatBox {
    height: 70%;
    padding: 0 10px 0 60px;
  }

  .message {
    max-width: 100%;
  }
  
  .iaAvatar {
    width: 45px;
    left: -55px;
  }

  .loadingCards {
    font-size: 2rem;
  }

  .inputContainer {
    width: 90%;
  }
}
