.bg-header{
    background-color: #2872ba;
    height: 50px;
    width: 100%;
    border-radius: 5px 5px 0 0;
    align-content: center;
}

.bg-header h5{
    font-size: 14px;
    text-align: center;
    color: White;
}

.content-chamado{
    padding: 10px;
    border: 1px solid #dddddd;
}

.lista-mensagens{
    height: 450px;
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
}