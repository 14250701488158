.cursor-pointer{
    cursor: pointer;
}
.title{
    padding: 0 !important;
    margin: 0 !important;
    font-weight: 500;
    font-size: 14px !important;
}

.mt-8{
    margin-top: 8px !important;
}


.last-message{    
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 0;
    font-size: 13px;
    color: #c6c6c6;
}

.chat-selected{
    background-color: #5a91ff !important;
    margin: 5px 0 5px 10px;
    border-radius: 5px;
}

.cursor-pointer:hover{
    background-color: #f3f3f3 !important;
}

.chat-selected p{
    color: #f3f3f3;
}
.chat-selected:hover{
    background-color: #71a0ff !important;
}

.chat-not-selected{    
    margin: 5px;
}

.mensagem-recebida p{
    padding: 5px 15px 5px 15px;
    background-color: #f3f3f3;
    display: inline;
    width: fit-content;
    border-radius: 10px;
    border: 1px solid #c6c6c6;
}

.mensagem-recebida{
    margin: 2px 0;
}

.mensagem-enviada p{
    padding: 5px 15px 5px 15px;
    background-color: #2269f5;
    color: #FFFFFF;
    display: inline;
    width: fit-content;
    border-radius: 10px;
    margin-right: 20px !important;
}

.mensagem-enviada{
    text-align: right;
}

.input-message input{
    text-transform: none !important;
}

.data-hora{
    margin: 0 22px 0 15px;
    font-size: 9px;
    color: #979797;
}

.nao-lidas{
    width: 57px;
    float: right;
    background-color: #2269f5;
    padding: 2px 4px;
    border-radius: 5px;
    font-size: 9px;
    color: #F3F3F3;
}

.icone-grupo{
    position: relative;
    bottom: 0;
}

.arquivo{
    display: inline;
    padding: 5px 0;
    border-radius: 5px;
    margin-right: 20px;
    margin-left: 12px;
    margin-bottom: 5px;
    background-color: #3a995e;
}
.arquivo a{
    color: #FFF;
    text-decoration: none;
}
.arquivo a i{
    position: relative;
    top: 4px;
    right: 5px;
    font-size: 18px !important;
}