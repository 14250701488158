@page {
    size: landscape !important;
}

.class-print{    
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;    
}

.tabela {
    border-collapse: collapse;
    width: 100%;
}
.tabela th, td {
    border: 1px solid black;
    text-align: center;
    width: 20px
}
.vertical {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}
.font{
    padding: 5px 0;
    font-size: 10px;
    margin: 0 !important;
}
.font-aula{
    font-size: 12px;
}

.dados-unidade{
    font-size: 14px;
}
.td-class {
    text-align: center;
    width: 20px;
    font-size: 11px !important;
}

.logo-container {
    display: inline-block;
    width: 30px; 
    vertical-align: top;
}

.text-container {
    display: inline-block;
    width: 96%; 
    vertical-align: top;
    padding-top: 25px; 
    text-align: center;
}