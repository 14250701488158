@media print {
  @page {
    size: landscape;
  }
  body {
    width: 100% !important;
  }
}

@media print {
  body {
    zoom: 80%;
  }
}
.class-print {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#jvlabelWrap {
  display: none !important;
}

/* .notranslate {
  display: none !important;
} */

.tb-quadro-horario thead th {
  font-size: 10px;
}

.tb-quadro-horario tbody tr td {
  font-size: 10px;
}

.box-info{
  height: 50px;
  background-color: #2872ba;
  border: 1px solid #285a8b;
  border-radius: 5px;
  margin: 10px 0;
}

.box-info p{
  color: #FFF;
}