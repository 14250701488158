
.btn-presenca{
    float: left !important;
    display: flex !important;
    align-items: center !important;
    align-content: center !important;
    width: 40px !important;
    height: 40px !important;
    border: 1px solid white !important;
    cursor: pointer !important;
    color: white !important;
  }
  
  .falta{
    background-color: red !important;
    padding-top: 7px;
    padding-left: 13px;
  }
  .presenca{
    background-color: green;
  }

  .presenca h3{
    color: white !important;
    margin:auto !important;
    vertical-align:middle !important;
  }