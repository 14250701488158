.tbmVisao{
    width: 100%;
    border: 1px solid #000;
    font-size: 12px;
}
.tbmVisao thead{
    
    height: 50px;
    width: 100%;
    border: 1px solid #000;
    padding-left: 5px !important;
    background-color: rgb(248, 248, 224);
}
.tbmVisao > thead > tr > th{
    border: 1px solid #000;
    padding-left: 5px !important;
}
.tbmVisao > tbody > tr > td{
    border: 1px solid #000;
    padding-left: 5px !important;
}

.txt-center{
    text-align: center;
}

.normal-font{
    font-weight: normal !important;
}
.rotate{
    font-size: 10px !important;
    transform: rotate(270deg)
}