.body-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 48px;
  background-image: url("https://pre-matricula.educacaoararuama.rj.gov.br/_form/images/fundo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* height: 100vh; */
}

.body-content table tbody tr td {
  font-weight: bold;
  margin-right: 50px;
  text-transform: uppercase;
}

.table-responsive .dropdown-menu.show {
  z-index: 1050; /* Ensure this is higher than the table's z-index */
  position: absolute;
  transform: translate3d(0, 0, 0); /* Optional: Adjusts for better positioning */
}

/* Make sure the table is in a relative container if necessary */
.table-responsive {
  position: relative;
}
.tbl-desktop {
  display: block;
}

.tbl-mobile {
  display: none;
}

@media (max-width: 720px) {
  .body-content {
    padding: 0 !important;
  }
  .kLexIl {
    width: 100vw !important;
    padding: 5px !important;
  }
}
