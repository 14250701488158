/* Estilo para o Carousel */
.carousel-control-next, .carousel-control-prev  {
    filter: invert(36%) sepia(85%) saturate(3228%) hue-rotate(199deg) brightness(96%) contrast(90%);

}

.carousel-indicators [data-bs-target] {
  background-color: #007bff; /* Cor azul para os indicadores */
}

.carousel-indicators .active {
    filter: invert(36%) sepia(85%) saturate(3228%) hue-rotate(199deg) brightness(96%) contrast(90%);

}