/* @media print{@page {size: landscape}} */

.class-print{    
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    
}
.logo-impressao{
    width: 100px !important;
}
.txt-center{
    font-weight: bold;
    font-size: 14px;
    vertical-align: middle !important;
}
.logo-sao-pedro{
    width: 220px !important;
}

.border-black{
    border: 1px solid black !important;
}

.border-top{
    border-top: 1px solid black !important;
}

.border-right{
    border-right: 1px solid black !important;
}

.border-bottom{
    border-bottom: 1px solid black !important;
}

.border-left{
    border-left: 1px solid black !important;
}

.bg-bege{
    background-color: rgb(191 187 167);
}

.bg-aliceblue{
    background-color: aliceblue;
}

.bg-antiquewhite{
    background-color: antiquewhite;
}

.bg-aqua{
    background-color: aqua;
}

.bg-azure{
    background-color: azure;
}

.remove-border-top{
    border-top: 0 !important;
}

.remove-border-right{
    border-right: 0 !important;
}

.remove-border-bottom{
    border-bottom: 0 !important;
}

.remove-border-left{
    border-left: 0 !important;
}
.produto{
    font-size: 12px !important;
}
.margin-top-zero{
    margin-top: 0 !important;
}
.margin-bottom-zero{
    margin-bottom: 0 !important;
}

.chart > canvas{
    margin: auto;
    height: 500px !important;
    margin-bottom: 30px;
}