/* Hide the print button when printing */
@media print {
  .no-print {
    display: none;
  }

  /* Ensure A4 page size and portrait mode */
  @page {
    size: A4 portrait;
    margin: 20mm;
  }

  /* Ensure the content fits the A4 page */
  body {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  /* Adjust paper content to fit in one page */
  .paper-content {
    page-break-inside: avoid;
    font-size: 12px; /* Adjust font size to fit content */
  }

  .logo-container img {
    max-width: 100px; /* Resize logo to fit better on the page */
  }

  .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}
