.report-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 900px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  position: relative;
  padding: 40px 0;
}

.logo-prefeitura {
  position: absolute;
  left: 20px;
  top: 55px;
}

.photo-cell {
  width: 150px;
}

.photo-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

header {
  text-align: center;
  margin-bottom: 20px;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.info-table td {
  border: 1px solid #000;
  padding: 8px;
  vertical-align: top;
}

.section-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.observations {
  margin-top: 20px;
  font-size: 0.9em;
  line-height: 1.5;
}

.signature-section {
  display: flex;
  justify-content: space-between;
  margin-top: 200px;
}

.signature-field {
  text-align: center;
  width: 30%;
}

.signature-line {
  border-top: 1px solid black;
  margin-bottom: 5px;
  width: 100%;
}
