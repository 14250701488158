.dropdown-item-success {
    color: #28a745; /* Bootstrap success color */
  }
  
  .dropdown-item-primary {
    color: #007bff; /* Bootstrap primary color */
  }
  
  .dropdown-item-warning {
    color: #ffc107; /* Bootstrap warning color */
  }

  .dropdown-item-danger {
    color: #dc3545; /* Bootstrap danger color */
  }